<template>
  <b-container class="usermanagement">
    <b-row>
      <b-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
        <h3>Email processing whitelists</h3>
        <b-button variant="primary" class="mx-1 my-1" @click="reloadLists">Reload whitelists</b-button>
        <h4>Listing type</h4>
        <b-form-textarea
          id="listingtype-textarea"
          v-model="listingTypesText"
          placeholder="Listing types, one per line"
          rows="8"
        ></b-form-textarea>
        <b-button
          variant="primary"
          class="mx-1 my-1"
          @click="submitListingTypes"
        >Submit Listing types</b-button>
        <h4>Private label (brand)</h4>
        <b-form-textarea
          id="privatelabel-textarea"
          v-model="privateLabelsText"
          placeholder="Private labels, one per line"
          rows="8"
        ></b-form-textarea>
        <b-button
          variant="primary"
          class="mx-1 my-1"
          @click="submitPrivateLabels"
        >Submit Private labels</b-button>
        <h4>Negotiating Country</h4>
        <b-form-textarea
          id="negotiatingCountry-textarea"
          v-model="negotiatingCountriesText"
          placeholder="Negotiating countries, one per line"
          rows="8"
        ></b-form-textarea>
        <b-button
          variant="primary"
          class="mx-1 my-1"
          @click="submitNegotiatingCountries"
        >Submit Negotiating countries</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/backendapi";
import { defaultToastBody, defaultToastConfig } from "@/util";

export default {
  name: "UserManagement",
  data: function() {
    return {
      listingTypesText: "",
      privateLabelsText: "",
      negotiatingCountriesText: ""
    };
  },
  computed: {
    currentUser: function() {
      return this.$store.state.user;
    }
  },
  methods: {
    reloadLists: async function() {
      let token = localStorage.token;
      try {
        // TODO: Optimization; can await all requests instead of sequentially (not properly async)
        let listingTypes = await api.getListingTypes(token);
        let privateLabels = await api.getPrivateLabels(token);
        let negotiatingCountries = await api.getNegotiatingCountries(token);
        this.listingTypesText = this.whitelistAsString(listingTypes.data);
        this.privateLabelsText = this.whitelistAsString(privateLabels.data);
        this.negotiatingCountriesText = this.whitelistAsString(negotiatingCountries.data);
        let toastConfig = defaultToastConfig();
        toastConfig.title = "Data loaded";
        toastConfig.variant = "success";
        toastConfig.autoHideDelay = 800; // Quicker message
        this.$bvToast.toast(
          `Whitelists loaded from server successfully.`,
          toastConfig
        );
      } catch (err) {
        console.log(err);
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    },
    whitelistAsString(entries) {
      return entries.join("\n");
    },
    submitListingTypes: async function() {
      let token = localStorage.token;
      let entries = this.listingTypesText.split("\n");
      let toastConfig = defaultToastConfig();
      toastConfig.title = "Data submitted";
      toastConfig.variant = "success";
      toastConfig.autoHideDelay = 800; // Quicker message
      try {
        api.postListingTypes(token, entries);
        this.$bvToast.toast(
          `Listing types whitelist set.`,
          toastConfig
        );
      } catch (err) {
        console.log(err);
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    },
    submitPrivateLabels: async function() {
      let token = localStorage.token;
      let entries = this.privateLabelsText.split("\n");
      let toastConfig = defaultToastConfig();
      toastConfig.title = "Data submitted";
      toastConfig.variant = "success";
      toastConfig.autoHideDelay = 800; // Quicker message
      try {
        api.postPrivateLabels(token, entries);
        this.$bvToast.toast(
          `Private labels whitelist set.`,
          toastConfig
        );
      } catch (err) {
        console.log(err);
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    },
    submitNegotiatingCountries: async function() {
      let token = localStorage.token;
      let entries = this.negotiatingCountriesText.split("\n");
      let toastConfig = defaultToastConfig();
      toastConfig.title = "Data submitted";
      toastConfig.variant = "success";
      toastConfig.autoHideDelay = 800; // Quicker message
      try {
        api.postNegotiatingCountries(token, entries);
        this.$bvToast.toast(
          `Negotiating Countries whitelist set.`,
          toastConfig
        );
      } catch (err) {
        console.log(err);
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    // If we have no users loaded, start the request immediately.
    if (this.listingTypesText.length === 0) {
      this.reloadLists();
    }
  }
};
</script>